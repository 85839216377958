/* Can be seen on appointments#show page */
@layer components {
  .detail-block {
    @apply relative pl-8;
  }

  .detail-icon {
    @apply absolute top-0 left-0 text-muted text-center w-6;
    font-size: 1.25rem;
    line-height: 1.2;
  }

  .detail-wrap {
    @apply border-dashed border-b border-neutral py-4;
  }

  .detail-wrap > strong {
    @apply block mb-4;
  }
}
