.form-builder {
  @apply grid grid-cols-6 gap-4;
}

.fb-middle {
  @apply col-span-4 sm:col-span-3 max-h-[83vh] overflow-y-auto;
}

.fb-left {
  @apply col-span-6 sm:col-span-1;
}

.fb-left .fb-add-field-types {
  @apply flex sm:flex-col gap-6;
}

.fb-left .fb-add-field-types .section {
  @apply flex flex-wrap sm:flex-col gap-1 items-start sm:self-stretch sm:items-stretch self-start;
}

.fb-left .fb-add-field-types .section .ui-draggable {
  @apply flex gap-1 justify-center flex-wrap;
}

.fb-middle .fb-response-fields {
  @apply flex flex-col gap-2;
}

.fb-middle .fb-response-fields .fb-field-wrapper {
  @apply border-box p-3;
}

.fb-middle .fb-response-fields .fb-field-wrapper.editing {
  @apply bg-primary/25;
}

.fb-middle .fb-response-fields .fb-field-wrapper .actions-wrapper {
  @apply flex justify-end gap-1;
}

.fb-right {
  @apply col-span-2;
}
