.Sorter {
  white-space: nowrap;
}

.Sorter a {
  margin-right: 10px;
}

.SorterDisabled {
}
