a.muted {
  @apply text-gray-500;
}

a.muted:hover {
  @apply text-gray-600;
}

@layer utilities {
  .text-muted {
    @apply text-gray-500;
  }

  .help-block {
    @apply block text-muted mb-2 text-sm;
  }

  .min-h-content {
    min-height: max(300px, calc(100vh - var(--header-topbar-height) - 5rem));
  }
}

.h-topbar {
  height: 60px;
}

.bg-unread {
  background-color: var(--unread-counter-background);
  color: var(--unread-counter-text);
}
