/* Copypasta from .form-control:focus node_modules/bootstrap/scss/forms/_form-control.scss:34 */
.Select__control.Select__control--is-focused {
  color: $input-focus-color;
  background-color: $input-focus-bg;
  border-color: $input-focus-border-color;
  outline: 0;
  @if $enable-shadows {
    @include box-shadow($input-box-shadow, $input-focus-box-shadow);
  } @else {
    // Avoid using mixin so we can pass custom focus shadow properly
    box-shadow: $input-focus-box-shadow;
  }
}

/*
Forms
The grid here is based on label sizes from the old design
and results in label width of ~200px and input width of ~65ch
on large screens.
 */
.form-horizontal {
  @apply flex flex-col gap-4;
  max-width: calc(65ch + 200px + 1rem);

  .control-group {
    @apply md:grid grid-cols-8 gap-4;
  }

  .control-label {
    @apply col-span-2 text-right;
    padding-top: 0.35rem;
    overflow-wrap: break-word;
  }

  .controls {
    @apply col-span-6 col-start-3;
  }

  .text-property input {
    width: 100%;
  }

  .url-property input {
    width: 100%;
  }

  .email-property input {
    width: 100%;
  }

  .help-block {
    margin-bottom: 0;
  }

  /* border color due to app/assets/stylesheets/lib/select2-bootstrap.css*/
  .control-group.string.error input,
  .control-group.date_range.error .date-range-input-view,
  .control-group.recurrence_pattern.error .recurrence-pattern-input-view,
  .control-group.application_configuration.error
    .application-configuration-view {
    border: 1px solid var(--btn-danger-border) !important;
  }
}

/*
Used for PageCreators CKEditor
 */
.page-edit-view .form-horizontal {
  max-width: 100%;
}

.form-input,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  @extend .form-control;
}

select {
  @extend .form-select;
}

input[type="checkbox"],
input[type="radio"] {
  @extend .form-check-input;
  flex-shrink: 0;
}

input[type="radio"]:checked,
input[type="checkbox"]:checked {
  background-color: var(--link-text);
  border-color: var(--link-text);
}

input[type="checkbox"]:disabled {
  background-color: var(--gray-200);
}

label.checkbox,
label.radio {
  @apply flex items-center;
}

label.checkbox input,
label.radio input {
  @apply m-0 mr-2;
}
