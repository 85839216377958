.header__content {
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--header-topbar-height);
  display: grid;
  background-color: var(--header-topbar-background);
  grid: var(--header-small-grid) var(--header-topbar-height) / 60px 1fr auto;
}

.header__burger {
  grid-area: burger;
  height: var(--header-topbar-height);
}

.tixxt--wide .header__burger {
  display: none;
}

.header__logo {
  grid-area: logo;
  display: var(--header-logo-display);
  background-color: var(--header-logo-background);
  place-items: stretch stretch;
  height: var(--header-logo-height);
  width: 100%;
  max-width: 280px;
  justify-self: center;
}

.header__logo-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__logo-img {
  max-width: 100%;
  max-height: var(--header-logo-height);
}

.header__home-icon {
  grid-area: home-icon;
  display: var(--header-home-icon-display);
  place-items: stretch start;
}

.header__search {
  grid-area: search;
  place-items: center stretch;
  display: var(--header-small-search-display);
  height: var(--header-topbar-height);
}

.header__user-central {
  display: flex;
  grid-area: user-central;
  align-items: stretch;
  padding-right: 1rem;
  justify-content: flex-end;
}

.header__banner {
  height: var(--header-banner-height);
  background: var(--header-banner-background);
  position: var(--header-banner-position);
  margin-top: var(--header-topbar-height);
  width: 100%;
  z-index: -1;
}

.user-central > li {
  align-items: stretch;
  height: var(--header-topbar-height);
}

@layer components {
  .header__link,
  .header__burger {
    color: var(--header-topbar-contrast);
    text-decoration: inherit;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .header__link:hover,
  .header__burger:hover {
    color: var(--header-topbar-contrast--hover);
  }
}

.notifications__title,
.chat__title {
  font-size: 0.7em;
  font-weight: 300;
}

.menu__indicator {
  --fa-primary-color: var(--header-topbar-background);
  --fa-secondary-color: var(--header-topbar-contrast);
  --fa-secondary-opacity: 1;
}

.header__link:hover .menu__indicator {
  --fa-secondary-color: var(--header-topbar-contrast--hover);
}

.dropdown-icon {
  width: 20px;
  margin-right: 5px;
}

.search-bar__icon {
  color: var(--search-bar__input-border-color);
}

.search-bar__focus {
  color: var(--search-bar__input--focus-color);
}

.search-bar__input {
  color: var(--search-bar__input-color);
  background: var(--search-bar__input-background);
  border-color: var(--search-bar__input-border-color, var(--gray-500));
}

.search-bar__input:focus {
  background: var(--search-bar__input--focus-background);
  color: var(--search-bar__input--focus-color);
  border-color: var(--search-bar__input--focus-border-color);
}

.search-bar__input:focus:hover {
  border-color: var(--search-bar__input--focus-border-color);
}

.search-bar__input:hover {
  border-color: var(--search-bar__input--hover-border);
}

/* 
 * larger screens (tailwind "lg:", alternative 800px) 
 */
@media screen and (min-width: 1024px) {
  .header__burger {
    display: none;
  }

  .header__search {
    display: flex;
  }

  .header__home-icon {
    grid-area: logo;
  }

  .header__home-icon > .header__link {
    width: 64px;
  }
}

/*
 * Screens larger than xl
 */
@media screen and (min-width: 1024px) {
  .header__content {
    grid:
      "logo search user-central" var(--header-topbar-height)
      / 17.5rem 7fr 2fr;
  }
}

/* Larger than max content width (tailwind "2xl:", alternative 1440px) */
@media screen and (min-width: 1536px) {
  .header__content {
    grid:
      "before logo search user-central after" var(--header-topbar-height)
      / 1fr 280px calc(1536px - (280px * 2)) 280px 1fr;
  }

  .tixxt--full .header__content {
    grid:
      "logo before search after user-central" var(--header-topbar-height)
      / 280px 1fr calc(1536px - (280px * 2)) 1fr 280px;
  }
}
