/*
 * DEPRECATED
 * Please add all colors and other variables via design manager
 */

:root {
  /* Votebar colors */
  --vote-bar-background-normal: #f5f5f5;
  --vote-bar-background-voted: rgba(var(--tailwind-primary) / 0.1);

  /* Constants */
  --header-topbar-height: 60px;

  /* BS Root Variables that are needed https://getbootstrap.com/docs/5.2/customize/css-variables/ */
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
}
