.module-switcher {
  background: var(--module-switcher-background);
  color: #fff;
}

.module-switcher__module {
  height: 4rem;
  width: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--module-switcher-text);
  overflow: hidden;
}

.module-switcher__module:hover {
  text-decoration: none;
  background: var(--module-switcher--hover-background);
  color: var(--module-switcher--hover-text);
}

.module-switcher__module--active,
.module-switcher__module--active:hover {
  background: var(--module-switcher--active-background);
  color: var(--module-switcher--active-text);
  --fa-secondary-opacity: 0.6;
}

.module-switcher-module__title {
  font-size: 0.5625rem;
  text-align: center;
}

.module-switcher-module__icon {
  font-size: 1.5rem;
  text-align: center;
}

.module-switcher__footer {
  margin-top: auto;
}

/*
 * Hide labels for screens between lg & xl
 */
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .module-switcher__module {
    height: 3rem;
    width: 2.5rem;
  }

  .module-switcher-module__title {
    display: none;
  }
}
