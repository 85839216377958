.chatbar {
  position: sticky;
  top: 0;
  align-self: flex-start;
}

.chatbar__content {
  background: var(--chat-background);
  color: var(--chat-text, var(--normal-text));
  height: 100vh;
  max-height: 100vh;
}

.chatbar__content .text-muted {
  color: rgba(var(--tailwind-chat-text, var(--tailwind-normal-text)) / 0.75);
}

.chat__message-composer,
.chat__message-composer:focus {
  background: var(--chat-composer-background);
  color: var(--chat-composer-text, var(--normal-text));
}

.chat__message-composer::placeholder {
  color: rgba(
    var(--tailwind-chat-composer-text, var(--tailwind-normal-text)) / 0.5
  );
}

/* Show static chat in its own grid-area to the right
 * when screen width over 2xl + chatbar min-width
 * Also see CHAT_STATIC_BREAKPOINT in app/javascript/layout/togglebar.ts */
@media (min-width: 1884px) {
  .tixxt.tixxt--with-chat {
    grid:
      "header header header header chatbar" auto
      "fillstart userbar navigation nav-fill chatbar" auto
      "fillstart userbar main fillend chatbar" 1fr
      / 1fr var(--userbar-width) calc(1536px - var(--userbar-width)) 1fr minmax(280px, 25vw);
  }

  .tixxt--full.tixxt.tixxt--with-chat {
    grid:
      "header header header header chatbar" auto
      "userbar navigation navigation navigation chatbar" auto
      "userbar fillstart main fillend chatbar" 1fr
      / var(--userbar-width) 1fr calc(1536px - var(--userbar-width)) 1fr minmax(280px, 25vw);
  }

  .tixxt--with-chat .header__content {
    grid:
      "before logo search user-central after chatbar" auto
      / 1fr 280px calc(1536px - (280px * 2)) 280px 1fr minmax(280px, 25vw);
  }

  .tixxt--full.tixxt--with-chat .header__content {
    grid:
      "logo before search after user-central chatbar" auto
      / 280px 1fr calc(1536px - (280px * 2)) 1fr 280px minmax(280px, 25vw);
  }

  .chatbar {
    grid-area: chatbar;
  }
}

/* Chatbar as overlay from the right, with backdrop on smaller screens
 * also applies to screens up to 1883px but backdrop is removed there */
@media screen and (max-width: 1883px) {
  /* INVISIBLE */
  .chatbar {
    position: fixed;
    right: 0;
    width: 0;
    height: 0;
    overflow: hidden;

    transition-property: background-color;
    transition-duration: 0.4s;
    background-color: rgba(0, 0, 0, 0);
  }

  .chatbar__content {
    position: absolute;
    height: 100vh;
    transition-property: right;
    transition-duration: 0.2s;
    width: 90vw;
    right: -90vw;
  }

  /* VISIBLE */
  .chatbar--visible {
    width: 100%;
    height: 100vh;
    overflow: hidden;

    background-color: rgba(0, 0, 0, 0.75);
  }

  .chatbar--visible .chatbar__content {
    right: 0%;
  }

  /* HIDING (additionally to --visible styles) */
  .chatbar--hiding {
    background-color: rgba(0, 0, 0, 0);
  }

  .chatbar--hiding .chatbar__content {
    right: -90%;
    max-width: 300px;
  }
}

/* Chatbar intermediate behaviour, overlay from the right but without backdrop
 * This applies to many screen sizes, from 768px (md) to 1883px (just before static chatbar) */
@media screen and (min-width: 768px) and (max-width: 1883px) {
  .chatbar--visible {
    width: calc(30% - 3rem); /* About widgetbar size from 1024px to 1535px */
    min-width: 347px;
    background-color: rgba(0, 0, 0, 0);
  }

  .chatbar__content {
    width: 100%;
    right: -100%;
  }

  .chatbar--hiding .chatbar__content {
    right: -100%;
  }
}

.chat__header {
  background: var(--chat-header-background);
  color: var(--chat-header-text);
}

.chat__header .text-muted {
  color: rgba(
    var(--tailwind-chat-header-text, var(--tailwind-normal-text)) / 0.75
  );
}

.chat__quick_select {
  display: grid;
  grid-template-rows: 64px;
  grid-template-columns: repeat(auto-fit, minmax(56px, 1fr));
  height: 64px;
  min-height: 64px;
  overflow: hidden;
}

.chat__list_row:hover,
.chat__quick_select_item:hover {
  background: var(--chat-list--hover-background);
}

.chat__quick_select_item--active,
.chat__quick_select_item--active:hover,
.chat__quick_select_item--active .chatIcon {
  background: var(--chat-accent);
  color: var(--chat-accent-contrast);
}

.chat__message {
  @apply rounded rounded-tl-none;
  background-color: var(--chat-message-background, white);
  margin: 0 16px;
  padding: 6px 8px;
  min-width: 20%;
  max-width: 85%;
  align-self: flex-start;
  transition: background-color 3s;
}

.chat__message .prose {
  color: var(--chat-message-text, var(--normal-text));
}

.chat__message .text-muted {
  color: rgba(
    var(--tailwind-chat-message-text, var(--tailwind-normal-text)) / 0.6
  );
}

.chat__message--my {
  @apply rounded rounded-tr-none;
  background-color: var(--chat-message--my-background);
  align-self: flex-end;
  padding-top: 2px;
  padding-bottom: 2px;
}

.chat__message--my .prose {
  color: var(--chat-message--my-text, var(--normal-text));
}

.chat__message--my .text-muted {
  color: rgba(
    var(--tailwind-chat-message--my-text, var(--tailwind-normal-text)) / 0.6
  );
}

.chat__message--grouped {
  @apply rounded;
  margin-top: -0.5rem;
  padding-top: 2px;
  padding-bottom: 2px;
}

.chat__message--unread {
  background-color: var(--unread__content-background);
}

/*.chat__bubble_arrow {*/
/*  border-style: solid;*/
/*  position: absolute;*/
/*  border-color: #e1e1e1 transparent transparent transparent;*/
/*  border-width: 14px 0 14px 14px;*/
/*  left: -14px;*/
/*  top: 0;*/
/*}*/

/*.chat__bubble_arrow:after {*/
/*  border-color: white transparent transparent transparent;*/
/*  border-style: solid;*/
/*  border-width: 14px 0px 14px 14px;*/
/*  bottom: -14px;*/
/*  content: "";*/
/*  position: absolute;*/
/*  left: -13px;*/
/*}*/

/*.chat__message--my .chat__bubble_arrow {*/
/*  border-width: 14px 14px 14px 0;*/
/*  right: -14px;*/
/*  left: auto;*/
/*}*/

/*.chat__message--grouped .chat__bubble_arrow {*/
/*  display: none;*/
/*}*/

/*.chat__message--grouped .chat__bubble_arrow:after {*/
/*  border: 0 none transparent;*/
/*}*/

/*.chat__message--my .chat__bubble_arrow:after {*/
/*  border-color: var(--chat-message--my-background) transparent transparent;*/
/*  border-width: 14px;*/
/*  bottom: -14px;*/
/*  left: -15px;*/
/*}*/

.chat__message--grouped .chat__message_header,
.chat__message--my .chat__message_header {
  display: none;
}

.chat__message_meta {
  line-height: 1;
  margin-top: -18px; /* bring meta to base of last text line */
  float: right;
  display: flex;
  align-items: center;
}

.chat__delivery_status {
  opacity: 0.5;
}

.chat__delivery_status--read {
  color: var(--chat-accent);
  opacity: 1;
}

/* meta inline after last text line */
.chat__message_text:after {
  margin-right: 40px;
  content: "";
  display: inline-block;
}

.chat__message--my .chat__message_text:after {
  margin-right: 60px;
}

.chat__message_text p:last-child {
  display: inline;
  margin: 0;
}

.chat__seperator > span {
  background: var(--chat-background);
}

.chat .online {
  text-indent: -999px;
  overflow: hidden;
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: 0;
  right: 0;
  border-radius: 6px;
  background-color: rgb(var(--tailwind-success));
  border: 1.5px solid white;
}

.chat .busy {
  text-indent: -999px;
  overflow: hidden;
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: 0;
  right: 0;
  border-radius: 6px;
  background-color: rgb(var(--tailwind-warning));
  border: 1.5px solid white;
}

.chat__status--busy {
  color: rgb(var(--tailwind-warning));
}

.chat__status--online {
  color: rgb(var(--tailwind-success));
}

.chat__quote {
  border-left: 0.25rem solid var(--chat-accent);
  background-color: rgba(255, 255, 255, 0.75);
  padding: 0.125rem 0.25rem;
  @apply bg-gray-50;
}

.chat__composing {
  bottom: -3px;
  right: -2px;
}

.chat .emoji {
  display: inline-block;
  line-height: 1.25;
}

.chat .emoji_1 {
  font-size: 4em;
}

.chat .emoji_2 {
  font-size: 3em;
}

.chat .emoji_3 {
  font-size: 2em;
}
