@layer components {
  .media {
    @apply flex flex-wrap gap-3 items-start justify-end;
  }

  .media > .media-object {
    flex-shrink: 0;
    margin-top: 5px;
  }

  .media > .media-body {
    @apply grow flex shrink-0 grow flex-col justify-center items-start basis-40 hyphens-auto;
  }

  .media-list {
    @apply divide-neutral divide-y;
  }
}
