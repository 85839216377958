@import "./media.css";
@import "./filter-bar.css";
@import "./select2.css";

.widget-title {
  @apply font-semibold uppercase mb-2;
}

.modal .modal-body {
  min-height: 400px;
}

.task-composer .mentions-kinder-wrap {
  display: block;
  width: 100%;
}

.task-title-wrap .task-shortcuts {
  position: absolute;
  right: 55px;
  top: 5px;
  z-index: 2;
  margin-top: 2px;
}
