.globalSearchFilterBar > li {
  float: none;
  display: inline-block;
}

.globalSearchFilterBar > li > a {
  line-height: 18px;
}

.searchQueryResult {
  width: 780px;
}

.searchQueryResult.folders_query,
.searchQueryResult.files_query {
  width: auto;
  min-width: 780px;
}

.searchQueryResult h3 i {
  display: inline-block;
  width: 28px;
  font-size: 90%;
  vertical-align: 2px;
}

.searchQueryResult h3 i:global(.icons-file) {
  vertical-align: -3px;
  padding-left: 0;
  padding-right: 0;
}

.showMore {
  margin-top: 10px;
}

.hit {
  margin-bottom: 5px;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 5px;
}

.hit:first-child {
  margin-top: 5px;
  border-top: 1px solid #e1e1e1;
  padding-top: 5px;
}

.hitLeft {
  width: 25px;
  float: left;
}

.hitRight {
  margin-left: 35px;
}

.hitRight > ul:last-child {
  margin-bottom: 0;
}

.hitMeta {
  color: #888;
  margin: 5px 0;
  @apply text-sm;
}

.fileHitShowIcon {
  margin-left: 5px;
}

.hitTitle {
  font-weight: bold;
}

.hitContent {
  margin-top: 9px;
}

.hit :global(.activity-object-question) {
  margin-bottom: 0px;
}

.hit :global(.activity-reply) {
  border: none;
  box-shadow: none;
  padding: 5px;
}

.hit :global(p) {
  margin: 0px;
}

.hit :global(.files-view) {
  margin-top: 15px;
}

.hit :global(.options) {
  margin-top: 5px;
}

.hit :global(.activity-object-survey .survey-option label) {
  padding: 5px 10px;
  cursor: default;
}

.hit :global(.accordion-heading) {
  padding: 5px;
}

.hit :global(.calendar-list.inline) {
  margin-top: 10px;
}

.hit :global(.activity-object-image .preview-large) {
  width: 250px;
}

.hit :global(.activity-object-link .link-left) {
  width: 100px;
}

.hit :global(.activity-object-link .link-right) {
  margin-left: 110px;
}

.FileHit > td {
  padding-bottom: 0;
}

.FileHitMeta {
  border-top: 0;
  padding-top: 0;
}

.FileHitMeta > td {
  @apply pb-2 px-2;
}

.FolderHit {
  composes: FileHit;
}

.FolderHitMeta {
  composes: FileHitMeta;
}

/* For manually striped tables, i.e. in file search results */
:global tr.striped > td,
:global tr.striped > th {
  background-color: #f9f9f9;
}

.FoundIn {
  composes: hitMeta;
  margin: 0;
}

.Highlights :global em {
  background-color: var(--alert-warning-background);
  color: var(--alert-warning-text);
  border: 1px solid var(--alert-warning-border);
  padding: 0 2px;
  margin: 0 -3px;
  display: inline-block;
}
