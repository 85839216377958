@media print {
  .tixxt__navigation,
  .tixxt__userbar,
  .tixxt__chatbar,
  .header__banner {
    display: none !important;
  }

  .tixxt__header {
    margin: 1rem 0;
  }

  .header__content {
    background: transparent;
    position: static;
    display: block;
  }

  .header__content > :not(.header__logo) {
    display: none;
  }

  .header__logo {
    margin: 0 auto;
  }

  .activity-interaction,
  .reply-composer-wrapper,
  .activity-context-menu,
  .activity-reply .reply-meta {
    display: none;
  }
}
