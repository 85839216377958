.select2-container {
  display: block;
}

.select2-container .select2-choices {
  border-radius: 4px;
  border: 1px solid lightgrey;
  background-color: transparent;
  background-image: none;
}

.select2-container .select2-choice {
  min-height: 40px;
  line-height: 40px;
  border-radius: 4px;
  border: 1px solid lightgrey;
  background-color: transparent;
  background-image: none;
}

.select2-container .select2-choice .select2-arrow {
  padding-top: 5px;
}

.select2-result-label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.select2-result-label img {
  width: 1.75rem;
  height: 1.75rem;
}

.select2-container-multi .select2-choices .select2-search-choice {
  display: flex;
  background: var(--tag-background);
  color: var(--tag-text);
  padding: 0.5rem 1rem;
  border: none;
}

.select2-container-multi .select2-search-choice-close {
  top: 8px;
}

#react-select-2-input {
  box-shadow: none;
}
