a.tag,
.tag a {
  @apply rounded py-1 px-3 text-normal text-sm;
  background-color: var(--tag-background);
  color: var(--tag-text);
}

.tag {
  display: flex;
}

a.tag {
  display: inline;
}

a.tag::before,
.tag a:first-child::before {
  content: "#";
}

a.tag--reader,
.tag--reader a {
  background-color: var(--tag--reader-background);
  color: var(--tag--reader-text);
}

.tag a:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 0.5rem;
}

.tag .tag--remove {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 0.25rem;
  padding-right: 0.5rem;
}
