table.tixxt-table {
  @apply w-full;
}

table.tixxt-table thead {
  @apply bg-gray-100 p-2 text-left;
}

table.tixxt-table thead th {
  @apply p-2 font-semibold;
}

table.tixxt-table tbody {
  @apply divide-y divide-neutral;
}

table.tixxt-table td {
  @apply p-2;
}
table.tixxt-table td:not([class*="align"]) {
  @apply align-top;
}

table.tixxt-table td.with-context-menu {
  @apply px-2 py-0 align-middle;
}

table.tixxt-table.table-hover tr:hover {
  @apply bg-gray-50;
}

th.sortable {
  cursor: pointer;
}
