/*
 * This file provides shims to enable bootstrap v2.3.2 HTML to be styled with v5.1
 * New code should use v5.1 HTML
 */

.nav:not(.unshim) > li > a {
  @extend .nav-link;
  border: 1px solid transparent;
  margin-bottom: -1px;
}

.nav.nav-tabs:not(.unshim) > li {
  @extend .nav-item;
}

.nav.nav-tabs:not(.unshim) > li.active > a,
.nav.nav-tabs:not(.unshim) > li.active > a:focus {
  @extend .nav-link;
  @extend .active;
}

.nav.nav-tabs:not(.unshim) > li > a:hover,
.nav.nav-tabs:not(.unshim) > li > a:focus {
  @extend .nav-link;
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}

.dropdown-menu:not(.unshim) > li > a {
  @extend .dropdown-item;
}

.dropdown-menu:not(.unshim) .divider {
  @extend .dropdown-divider;
}

.modal-title {
  font-size: 1.25rem;
}

.modal-body .modal-footer {
  margin: 0 -1rem -1rem;
}

.row {
  max-width: 800px;
  position: relative;
}

.span12 {
  max-width: 940px;
}

.span11 {
  max-width: 860px;
}

.span10 {
  max-width: 780px;
}

.span9 {
  max-width: 700px;
}

.span8 {
  max-width: 620px;
}

.span7 {
  max-width: 540px;
}

.span6 {
  max-width: 460px;
}

.span5 {
  max-width: 380px;
}

.span4 {
  max-width: 300px;
}

.span3 {
  max-width: 220px;
}

.span2 {
  max-width: 140px;
}

.span1 {
  max-width: 60px;
}

// remove button outlines
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

.btn-link {
  color: var(--link-text);
}

.btn-link:hover {
  color: var(--link--hover-text);
}
