.reply-content.Composer.object-content-input,
.activity-object-editor .object-content-input,
.Composer.object-content-input {
  height: auto;
  width: auto;
  overflow: visible;
  position: relative;
}

.reply-content.Composer.object-content-input.appointmentDescriptionComposerStream,
.activity-object-editor
  .object-content-input.appointmentDescriptionComposerStream,
.Composer.object-content-input.appointmentDescriptionComposerStream {
  width: 100%;
}

.reply-content.Composer.object-content-input.readOnly .DraftEditor-root,
.activity-object-editor .object-content-input.readOnly .DraftEditor-root,
.Composer.object-content-input.readOnly .DraftEditor-root {
  background-color: #eaeaea;
}

.reply-content.Composer.object-content-input .placeholder,
.activity-object-editor .object-content-input .placeholder,
.Composer.object-content-input .placeholder {
  color: #999;
}

.reply-content.Composer.object-content-input
  .public-DraftEditorPlaceholder-root,
.activity-object-editor
  .object-content-input
  .public-DraftEditorPlaceholder-root,
.Composer.object-content-input .public-DraftEditorPlaceholder-root {
  color: #999;
}

.reply-content.Composer.object-content-input .suggestion,
.activity-object-editor .object-content-input .suggestion,
.Composer.object-content-input .suggestion {
  text-align: left;
  padding-top: 3px;
}

.reply-content.Composer.object-content-input .suggestion > span,
.activity-object-editor .object-content-input .suggestion > span,
.Composer.object-content-input .suggestion > span {
  vertical-align: middle;
  margin-bottom: 0;
  font-size: 13px;
}

.reply-content.Composer.object-content-input .suggestion > img,
.activity-object-editor .object-content-input .suggestion > img,
.Composer.object-content-input .suggestion > img {
  border-radius: 0;
}

.reply-content.Composer.object-content-input .DraftEditor-root,
.activity-object-editor .object-content-input .DraftEditor-root,
.Composer.object-content-input .DraftEditor-root {
  min-height: 122px;
  cursor: text;
}

.reply-content.Composer.object-content-input .public-DraftStyleDefault-ol,
.activity-object-editor .object-content-input .public-DraftStyleDefault-ol,
.Composer.object-content-input .public-DraftStyleDefault-ol,
.reply-content.Composer.object-content-input .public-DraftStyleDefault-ul,
.activity-object-editor .object-content-input .public-DraftStyleDefault-ul,
.Composer.object-content-input .public-DraftStyleDefault-ul {
  margin: 0;
}

.reply-content.Composer.object-content-input h1,
.activity-object-editor .object-content-input h1,
.Composer.object-content-input h1 {
  font-family: inherit;
  font-size: 1.5em;
  color: inherit;
  font-weight: 700;
}

.reply-content.Composer.object-content-input h4,
.activity-object-editor .object-content-input h4,
.Composer.object-content-input h4 {
  font-family: inherit;
  font-weight: 600;
  margin-top: 1em;
  font-size: 0.95em;
  margin-bottom: 0.25em;
  line-height: 1.5;
}

.reply-content.Composer.object-content-input h5,
.activity-object-editor .object-content-input h5,
.Composer.object-content-input h5 {
  font-family: inherit;
  font-weight: 600;
  margin-top: 0.5em;
  font-size: 0.85em;
  margin-bottom: 0;
  line-height: 1;
}

.reply-content.Composer.object-content-input .DraftEditor-editorContainer pre,
.activity-object-editor .object-content-input .DraftEditor-editorContainer pre,
.Composer.object-content-input .DraftEditor-editorContainer pre {
  position: relative;
}

.reply-content.Composer.object-content-input
  .DraftEditor-editorContainer
  pre
  .code-block-language-select
  .dropdown,
.activity-object-editor
  .object-content-input
  .DraftEditor-editorContainer
  pre
  .code-block-language-select
  .dropdown,
.Composer.object-content-input
  .DraftEditor-editorContainer
  pre
  .code-block-language-select
  .dropdown {
  position: absolute;
  top: 5px;
  right: 5px;
}

.reply-content.Composer.object-content-input
  .DraftEditor-editorContainer
  pre
  .code-block-language-select
  small,
.activity-object-editor
  .object-content-input
  .DraftEditor-editorContainer
  pre
  .code-block-language-select
  small,
.Composer.object-content-input
  .DraftEditor-editorContainer
  pre
  .code-block-language-select
  small {
  position: absolute;
  top: -9px;
  padding: 0 5px;
  left: 5px;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-size: 0.8em;
  line-height: 16px;
}

.reply-content.Composer.object-content-input
  .DraftEditor-editorContainer
  blockquote,
.activity-object-editor
  .object-content-input
  .DraftEditor-editorContainer
  blockquote,
.Composer.object-content-input .DraftEditor-editorContainer blockquote {
  margin: 0;
}

.reply-content.Composer.object-content-input
  .DraftEditor-editorContainer
  .align-left
  div,
.activity-object-editor
  .object-content-input
  .DraftEditor-editorContainer
  .align-left
  div,
.Composer.object-content-input .DraftEditor-editorContainer .align-left div {
  text-align: left;
}

.reply-content.Composer.object-content-input
  .DraftEditor-editorContainer
  .align-center
  div,
.activity-object-editor
  .object-content-input
  .DraftEditor-editorContainer
  .align-center
  div,
.Composer.object-content-input .DraftEditor-editorContainer .align-center div {
  text-align: center;
}

.reply-content.Composer.object-content-input
  .DraftEditor-editorContainer
  .align-right
  div,
.activity-object-editor
  .object-content-input
  .DraftEditor-editorContainer
  .align-right
  div,
.Composer.object-content-input .DraftEditor-editorContainer .align-right div {
  text-align: right;
}

.reply-content.Composer.object-content-input
  .DraftEditor-editorContainer
  .align-justify
  div,
.activity-object-editor
  .object-content-input
  .DraftEditor-editorContainer
  .align-justify
  div,
.Composer.object-content-input .DraftEditor-editorContainer .align-justify div {
  text-align: justify;
}

.reply-content.Composer.object-content-input .draftJsToolbar__toolbar__dNtBH,
.activity-object-editor .object-content-input .draftJsToolbar__toolbar__dNtBH,
.Composer.object-content-input .draftJsToolbar__toolbar__dNtBH {
  box-shadow: none;
  border-bottom: 1px solid #ccc;
  background: none;
  box-sizing: border-box;
  z-index: 2;
  padding: 0;
}

.reply-content.Composer.object-content-input
  .draftJsToolbar__toolbar__dNtBH:before,
.activity-object-editor
  .object-content-input
  .draftJsToolbar__toolbar__dNtBH:before,
.Composer.object-content-input .draftJsToolbar__toolbar__dNtBH:before {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.reply-content.Composer.object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__buttonWrapper,
.activity-object-editor
  .object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__buttonWrapper,
.Composer.object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__buttonWrapper {
  display: inline-block;
  vertical-align: middle;
}

.reply-content.Composer.object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__separator,
.activity-object-editor
  .object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__separator,
.Composer.object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__separator {
  height: 28px;
  display: inline-block;
  border-right: 1px solid #ddd;
  vertical-align: middle;
  margin: 0 0.5em;
}

.reply-content.Composer.object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__button,
.activity-object-editor
  .object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__button,
.Composer.object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__button {
  font-size: 16px;
  line-height: 18px;
  height: 28px;
  min-width: 28px;
  width: auto;
  vertical-align: middle;
  padding: 0 5px;
  background: none;
  color: #666;
  border: 0 none;
}

.reply-content.Composer.object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__button:hover,
.activity-object-editor
  .object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__button:hover,
.Composer.object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__button:hover {
  background-color: var(--primary-color);
}

.reply-content.Composer.object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__button:disabled,
.activity-object-editor
  .object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__button:disabled,
.Composer.object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__button:disabled {
  color: #999;
  cursor: not-allowed;
}

.reply-content.Composer.object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__buttonActive,
.activity-object-editor
  .object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__buttonActive,
.Composer.object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__buttonActive {
  background-color: #e1e1e1;
  color: #006dce;
}

.reply-content.Composer.object-content-input .e13wqaj6,
.activity-object-editor .object-content-input .e13wqaj6,
.Composer.object-content-input .e13wqaj6,
.reply-content.Composer.object-content-input .e8k2yoa,
.activity-object-editor .object-content-input .e8k2yoa,
.Composer.object-content-input .e8k2yoa {
  width: auto;
  height: inherit;
  padding: 0 2px;
  border: 0 none;
  border-radius: 0;
  color: #006dce;
  background: #fff;
}

.reply-content.Composer.object-content-input .e13wqaj6:hover,
.activity-object-editor .object-content-input .e13wqaj6:hover,
.Composer.object-content-input .e13wqaj6:hover,
.reply-content.Composer.object-content-input .e8k2yoa:hover,
.activity-object-editor .object-content-input .e8k2yoa:hover,
.Composer.object-content-input .e8k2yoa:hover,
.reply-content.Composer.object-content-input .e13wqaj6:focus,
.activity-object-editor .object-content-input .e13wqaj6:focus,
.Composer.object-content-input .e13wqaj6:focus,
.reply-content.Composer.object-content-input .e8k2yoa:focus,
.activity-object-editor .object-content-input .e8k2yoa:focus,
.Composer.object-content-input .e8k2yoa:focus,
.reply-content.Composer.object-content-input .e13wqaj6:active,
.activity-object-editor .object-content-input .e13wqaj6:active,
.Composer.object-content-input .e13wqaj6:active,
.reply-content.Composer.object-content-input .e8k2yoa:active,
.activity-object-editor .object-content-input .e8k2yoa:active,
.Composer.object-content-input .e8k2yoa:active {
  background: transparent;
  color: #0074ba;
}

.reply-content.Composer.object-content-input
  .draftJsEmojiPlugin__emojiSelectPopoverEntry__1ErDJ,
.activity-object-editor
  .object-content-input
  .draftJsEmojiPlugin__emojiSelectPopoverEntry__1ErDJ,
.Composer.object-content-input
  .draftJsEmojiPlugin__emojiSelectPopoverEntry__1ErDJ,
.reply-content.Composer.object-content-input
  .draftJsEmojiPlugin__emojiSelectPopoverEntryFocused__M28XS,
.activity-object-editor
  .object-content-input
  .draftJsEmojiPlugin__emojiSelectPopoverEntryFocused__M28XS,
.Composer.object-content-input
  .draftJsEmojiPlugin__emojiSelectPopoverEntryFocused__M28XS {
  font-size: 20px;
  line-height: 20px;
}

.reply-content.Composer.object-content-input
  .draftJsEmojiPlugin__emojiSuggestions__2ffcV,
.activity-object-editor
  .object-content-input
  .draftJsEmojiPlugin__emojiSuggestions__2ffcV,
.Composer.object-content-input .draftJsEmojiPlugin__emojiSuggestions__2ffcV {
  display: block;
  text-align: left;
}

.reply-content.Composer.object-content-input .redo-button,
.activity-object-editor .object-content-input .redo-button,
.Composer.object-content-input .redo-button,
.reply-content.Composer.object-content-input .undo-button,
.activity-object-editor .object-content-input .undo-button,
.Composer.object-content-input .undo-button,
.reply-content.Composer.object-content-input .fullscreen-button,
.activity-object-editor .object-content-input .fullscreen-button,
.Composer.object-content-input .fullscreen-button {
  font-size: 0.9em;
}

.reply-content.Composer.object-content-input .redo-button,
.activity-object-editor .object-content-input .redo-button,
.Composer.object-content-input .redo-button {
  padding: 0;
}

.reply-content.Composer.object-content-input .composer-statistics,
.activity-object-editor .object-content-input .composer-statistics,
.Composer.object-content-input .composer-statistics {
  margin-top: 7px;
  padding-top: 2px;
  color: #999;
  font-size: 0.8em;
  border-top: 1px solid #ccc;
}

.reply-content.Composer.object-content-input .composer-statistics ul,
.activity-object-editor .object-content-input .composer-statistics ul,
.Composer.object-content-input .composer-statistics ul {
  margin-bottom: 0;
}

.reply-content.Composer.object-content-input.closed,
.activity-object-editor .object-content-input.closed,
.Composer.object-content-input.closed {
  padding-top: 6px;
  min-height: 18px;
}

.reply-content.Composer.object-content-input.closed .DraftEditor-root,
.activity-object-editor .object-content-input.closed .DraftEditor-root,
.Composer.object-content-input.closed .DraftEditor-root {
  min-height: auto;
}

.draftJsMentionPlugin__mentionSuggestions__2DWjA {
  z-index: 5;
}

.reply-content.Composer.object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__button {
  width: 26px;
  min-width: 26px;
  padding: 0 2px;
}

.reply-content.Composer.object-content-input
  .draftJsToolbar__toolbar__dNtBH
  .draftJsToolbar__separator {
  margin: 0 0.25em;
}

.tixxt-modal-body .object-content-input,
.activity-object-editor .object-content-input {
  margin-bottom: 10px;
  max-height: 385px;
  min-height: 300px;
  width: auto;
}

.tixxt-modal-body .object-content-input .DraftEditor-root,
.activity-object-editor .object-content-input .DraftEditor-root {
  max-height: 296px;
  min-height: 296px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.tixxt-modal-body .object-content-input .draftJsEmojiPlugin__emojiSelect__34S1B,
.activity-object-editor
  .object-content-input
  .draftJsEmojiPlugin__emojiSelect__34S1B {
  position: relative;
}

.tixxt-modal-body
  .object-content-input
  .draftJsEmojiPlugin__emojiSelectPopover__1J1s0,
.activity-object-editor
  .object-content-input
  .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
  top: -275px;
  right: 0px;
}

.tixxt-modal-body .modal-body .Composer.object-content-input {
  overflow-y: scroll;
  overflow-x: hidden;
  margin: -16px -16px 0;
}

.htmlComposerWrapper {
  padding: 0 !important;
}

.htmlComposerToolbar {
  padding-top: 0px !important;
  background-color: #fbfbfb;
  border-bottom: 1px solid #ccc;
}

.rdw-option-wrapper,
.rdw-dropdown-selectedtext {
  margin: 0;
  border: 0;
  padding: 0 5px;
  height: 25px;
  opacity: 0.6;
}

.rdw-option-wrapper.quellcodeWrapper,
.rdw-dropdown-selectedtext.quellcodeWrapper,
.rdw-option-wrapper.imageWrapper,
.rdw-dropdown-selectedtext.imageWrapper {
  opacity: 1;
  background-color: #fbfbfb;
}

.rdw-option-wrapper.quellcodeWrapper .rdw-image-modal-btn,
.rdw-dropdown-selectedtext.quellcodeWrapper .rdw-image-modal-btn,
.rdw-option-wrapper.imageWrapper .rdw-image-modal-btn,
.rdw-dropdown-selectedtext.imageWrapper .rdw-image-modal-btn {
  min-width: 75px;
  width: auto;
}

.rdw-option-wrapper.rdw-option-disabled,
.rdw-dropdown-selectedtext.rdw-option-disabled {
  opacity: 0.3;
}

.rdw-option-wrapper:hover,
.rdw-dropdown-selectedtext:hover {
  background-color: #f4f4f4;
}

.htmlComposerEditor {
  padding: 0 12px;
}

.modal-fullscreen .Composer .ejr02pv {
  right: 20px;
  bottom: 50px;
}
