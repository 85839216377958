.Address {
  margin: 0;
}

.Address span {
  display: block;
}

.Address :global .name {
  font-weight: bold;
}

.Date {
}
.Email {
}
.Html {
}
.Image {
}
.Selection {
}
.Tag {
}
.Headline {
}
.Relation {
}
.Text {
}

.Image a {
  margin: 0 10px 10px 0;
  display: inline-block;
}

.GenericValueList,
.Selection,
.MembershipRelation {
  list-style: none;
  margin: 0;
}

.Selection li,
.Tag li {
  display: inline;
  margin-bottom: 0;
  margin-right: 5px;
}

.Tag :global .label:after {
  content: "";
}

.Tag :global .link:after {
  content: ", ";
}

.Tag :global .link:last-child:after {
  content: "";
}

.GenericValueList img {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}

.MembershipRelation li {
  margin-bottom: 5px;
}

.Statistics {
  display: inline-block;
}

.Statistics i,
.Tag i {
  margin-right: 3px;
}

/* special table view styles */
td .Address span,
td .GenericValueList li,
td .MembershipRelation li {
  display: inline;
  margin-bottom: 0;
}

.Selection li:after,
td .MembershipRelation li:after,
td .GenericValueList li:after,
td .Address span:after {
  content: ", ";
}

.Selection li:last-child:after,
td .MembershipRelation li:last-child:after,
td .GenericValueList li:last-child:after,
td .Address span:last-child:after {
  content: "";
}

td .Image img {
  width: 25px;
  height: 25px;
}

td .Image a {
  margin: 0 5px 0 0;
}
