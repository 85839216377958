// Workaround bootstrap setting and calculating default stuff
$theme-colors: ();

// Inject css variables
$nav-link-color: var(--link-color);
$btn-link-color: var(--link-color);
$nav-link-hover-color: var(--link--hover-color);

/* badges */
$badge-padding-x: 0.5em;

/* DropDown */
$enable-caret: false;

/* inputs */
$input-focus-border-color: rgb(var(--tailwind-primary));
$input-focus-box-shadow: inset 0 0 0 0.25rem
  rgba(var(--tailwind-primary) / 0.25);
