.btn-xl,
.btn-group-xl > .btn {
  --bs-btn-padding-y: 0.75rem;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-font-size: 1.5rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

a.btn {
  text-decoration: none;
}

.btn.disabled {
  @apply cursor-default;
}

/* primary and success */
.btn-primary,
.btn-success {
  --bs-btn-color: var(--btn-primary-text);
  --bs-btn-bg: var(--btn-primary-background);
  --bs-btn-border-color: var(--btn-primary-border);
  --bs-btn-hover-color: var(--btn-primary--hover-text, var(--btn-primary-text));
  --bs-btn-hover-bg: var(--btn-primary--hover-background);
  --bs-btn-hover-border-color: var(--btn-primary--hover-border);
  --bs-btn-active-color: var(
    --btn-primary--hover-text,
    var(--btn-primary-text)
  );
  --bs-btn-active-bg: var(--btn-primary--hover-background);
  --bs-btn-active-border-color: var(--btn-primary--hover-border);
  --bs-btn-disabled-color: var(
    --btn-primary--hover-text,
    var(--btn-primary-text)
  );
  --bs-btn-disabled-bg: var(--btn-primary--hover-background);
  --bs-btn-disabled-border-color: var(--btn-primary--hover-border);
}

.btn {
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-border-color: transparent;
}

.accordion-button-success {
  background: var(--alert-success-background);
  color: var(--alert-success-text);
  border-color: var(--alert-success-border);
}

/* light  and warning */
.btn-light,
.btn-warning {
  --bs-btn-color: var(--btn-light-text);
  --bs-btn-bg: var(--btn-light-background);
  --bs-btn-border-color: var(--btn-light-border);
  --bs-btn-hover-color: var(--btn-light--hover-text, var(--btn-light-text));
  --bs-btn-hover-bg: var(--btn-light--hover-background);
  --bs-btn-hover-border-color: var(--btn-light--hover-border);
  --bs-btn-active-color: var(--btn-light--hover-text, var(--btn-light-text));
  --bs-btn-active-bg: var(--btn-light--hover-background);
  --bs-btn-active-border-color: var(--btn-light--hover-border);
  --bs-btn-disabled-color: var(--btn-light--hover-text, var(--btn-light-text));
  --bs-btn-disabled-bg: var(--btn-light--hover-background);
  --bs-btn-disabled-border-color: var(--btn-light--hover-border);
}

/* danger */
.btn-danger {
  --bs-btn-color: var(--btn-danger-text);
  --bs-btn-bg: var(--btn-danger-background);
  --bs-btn-border-color: var(--btn-danger-border);
  --bs-btn-hover-color: var(--btn-danger--hover-text, var(--btn-light-text));
  --bs-btn-hover-bg: var(--btn-danger--hover-background);
  --bs-btn-hover-border-color: var(--btn-danger--hover-border);
  --bs-btn-active-color: var(--btn-danger--hover-text, var(--btn-light-text));
  --bs-btn-active-bg: var(--btn-danger--hover-background);
  --bs-btn-active-border-color: var(--btn-danger--hover-border);
  --bs-btn-disabled-color: var(--btn-danger--hover-text, var(--btn-light-text));
  --bs-btn-disabled-bg: var(--btn-danger--hover-background);
  --bs-btn-disabled-border-color: var(--btn-danger--hover-border);
}

.btn-alert-success {
  --bs-btn-color: var(--alert-success-text);
  --bs-btn-bg: var(--alert-success-background);
  --bs-btn-active-bg: var(--alert-success-background);
  --bs-btn-active-border-color: var(--alert-success-border);
  --bs-btn-hover-bg: var(--alert-success-border);
  --bs-btn-hover-border-color: var(--alert-success-border);
}

.btn-secondary {
  --bs-btn-color: var(--btn-secondary-text);
  --bs-btn-bg: var(--btn-secondary-background);
  --bs-btn-border-color: var(--btn-secondary-border);
  --bs-btn-hover-color: var(--btn-secondary--hover-text, var(--btn-light-text));
  --bs-btn-hover-bg: var(--btn-secondary--hover-background);
  --bs-btn-hover-border-color: var(--btn-secondary--hover-border);
  --bs-btn-active-color: var(
    --btn-secondary--hover-text,
    var(--btn-light-text)
  );
  --bs-btn-active-bg: var(--btn-secondary--hover-background);
  --bs-btn-active-border-color: var(--btn-secondary--hover-border);
  --bs-btn-disabled-color: var(
    --btn-secondary--hover-text,
    var(--btn-light-text)
  );
  --bs-btn-disabled-bg: var(--btn-secondary--hover-background);
  --bs-btn-disabled-border-color: var(--btn-secondary--hover-border);
}

/* grey */
.btn-gray {
  --bs-btn-color: var(--gray-900);
  --bs-btn-bg: var(--gray-400);
  --bs-btn-active-bg: var(--gray-400);
  --bs-btn-active-border-color: var(--gray-400);
}
