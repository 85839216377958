.userbar {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-size: 13px;
}

.userbar__content {
  @apply empty:hidden;
  background: var(--userbar-background);
  display: grid;
  grid:
    "module-switcher context-navigation" auto
    / min-content 13.5rem;
}

.userbar__module-switcher {
  grid-area: module-switcher;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.userbar__context-navigation {
  grid-area: context-navigation;
  overflow: auto;
}

.userbar-section:first-child {
  margin-top: 0.5rem;
}

.userbar-section__title {
  color: var(--userbar-section__title-text);
}

.userbar-section__content {
  background-color: var(--userbar-section-background);
  border-radius: 4px;
  padding: 0.25rem 0;
}

.userbar-section--closed .section-content__hide {
  display: none;
}

.userbar-link > a {
  color: var(--userbar-link-text);
  word-break: break-word;
  hyphens: auto;
}

.userbar-section-hyphen:hover {
  hyphens: auto;
}

.userbar-link .userbar-link-hint {
  color: var(--userbar-link-text);
}

.userbar-link--active > a {
  color: var(--userbar-link--active-text);
}

.userbar-link:hover {
  background: var(--userbar-link--hover-background);
}

.userbar-link:hover > a {
  color: var(--userbar-link--hover-text);
}

.userbar-link--active,
.userbar-link--active:hover {
  background: var(--userbar-link--active-background);
}

.userbar-link--active:hover > a {
  color: var(--userbar-link--active-text);
}

.userbar-link__menu {
  display: none;
}

.userbar-link:hover .userbar-link__menu,
.userbar-link:focus .userbar-link__menu {
  display: inherit;
  font-size: 13px;
}

.userbar-link__unreads {
  align-self: flex-end;
  padding: 0 0.5em 0.25em 0;
}

.userbar-link:hover .userbar-link__unreads {
  display: none;
}

.userbar-link--unreads {
  @apply font-semibold;
}

.userbar-section__indicator {
  margin-right: 0.5rem;
  transform: rotate(90deg);
  transition-property: transform;
  transition-duration: 0.2s;
  color: var(--userbar-section__indicator-color);
}

.userbar-section--add-group {
  color: var(--userbar-section__indicator-color);
}

.userbar-section--add-group:hover {
  text-decoration: none;
}

.userbar-section--closed .userbar-section__indicator {
  transform: rotate(0deg);
}

/*
 * Make Userbar toggleable
 * Smaller screens only (smaller than tailwind "lg:")
 */
@media screen and (max-width: 1023px) {
  /* INVISIBLE */
  .tixxt__userbar {
    position: fixed;
    width: 0;
    height: 0;
    overflow: hidden;
    top: 0;

    transition-property: background-color;
    transition-duration: 0.4s;
    background-color: rgba(0, 0, 0, 0);
  }

  .userbar .userbar__content {
    grid:
      "module-switcher context-navigation" auto
      / 4rem auto;
    position: absolute;
    height: 100vh;
    transition-property: left;
    transition-duration: 0.2s;
    width: min(90vw, 400px);
    left: calc(min(90vw, 400px) * -1);
  }

  .tixxt--in-app .userbar .userbar__content {
    grid:
      "context-navigation" auto
      / auto;
    position: absolute;
    height: 100vh;
    transition-property: left;
    transition-duration: 0.2s;
    width: min(80vw, 400px);
  }

  .tixxt--in-app .userbar .userbar__module-switcher {
    display: none;
  }

  /* VISIBLE */
  .userbar--visible {
    width: 100%;
    height: 100vh;
    overflow: auto;

    background-color: rgba(0, 0, 0, 0.75);
  }

  .userbar--visible .userbar__content {
    left: 0;
  }

  /* HIDING (additionally to --visible styles) */
  .userbar--hiding {
    background-color: rgba(0, 0, 0, 0);
  }

  .userbar--hiding .userbar__content {
    left: -90%;
  }
}
