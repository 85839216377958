.mobile_app_chat__list {
  margin-top: var(--header-topbar-height);
}

.mobile_app_chat_room_android {
  margin-top: var(--header-topbar-height);
}

.mobile_app_chat_room_ios {
  margin-top: calc(
    var(--header-topbar-height) + 44pt
  ); /* header height + standard iOS NavigationBar height */
  padding-top: calc(
    var(--header-topbar-height) + 44pt
  ); /* header height + standard iOS NavigationBar height */
}
