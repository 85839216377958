.widget__container {
  @apply rounded overflow-hidden;
  background: var(--channel-widget-background, var(--gray-50));
  display: grid;
  grid: 3rem 21rem / 100%;
  --link-text: var(--channel-link-text);
  --link--hover-text: var(--channel-link--hover-text);
}

.widget__title {
  background: var(--channel-widget-title-background, var(--tag-background));
}

.widget__label {
  @apply rounded px-2 py-1;
}

.widget__label--spotlight {
  background: var(--channel-label-spotlight-background);
  color: var(--channel-label-spotlight-text);
}
.widget__label--current {
  background: var(--channel-label-current-background);
  color: var(--channel-label-current-text);
}
.widget__label--important {
  background: var(--channel-label-important-background);
  color: var(--channel-label-important-text);
}

.images-widget--1 {
  grid: 100% / 100%;
}

.images-widget--2 {
  grid: 100% / 50% 50%;
}

.images-widget--4 {
  grid: 50% 50% / 50% 50%;
}

.images-gallery__dialog {
  background-color: rgba(0, 0, 0, 0.5);
}

.tixxt__dialog:focus-visible {
  outline: none;
}

.tixxt__dialog::backdrop {
  backdrop-filter: grayscale(50%) blur(8px);
}

.images-gallery__lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  opacity: 1;
  transition: opacity 450ms ease-in-out;
  align-items: center;
  justify-content: center;
}

.images-gallery__lightbox .content {
  max-width: 90%;
  position: relative;
}

.images-gallery__lightbox img {
  max-height: 90vh;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.channel-widgets-design__preview {
  background: var(--channel-background, var(--white)) !important;
}
