.Text {
}

.SimpleInput {
}

.Date {
}

.Date {
  width: 100%;
}

.Date input {
  width: calc(100% - 41px);
}

.Address {
  margin-bottom: -10px;
}

.AddressRow {
  margin-bottom: 10px;
}

.CityRow,
.StreetRow {
  composes: AddressRow;
}

.CityRow input,
.StreetRow input {
  width: calc(100% - 94px);
  float: left;
}

.AddressNumber input {
  width: 54px;
  margin-left: 10px;
}

.AddressZip input {
  width: 54px;
  margin-right: 10px;
}

.Email {
}

.File {
}

.File :global .fileUploadField {
  display: flex;
}

.File :global .fileUploadField .imageView {
  margin-right: 5px;
}

.File :global .fileUploadField .imageView img {
  height: 70px;
}

.File :global .fileUploadField .imageView .imageIcon {
  height: 90px;
  width: 100px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
}

.File :global .fileUploadField .imageView .fa {
  align-self: center;
  font-size: 70px;
  color: #c5c5c5;
}

.File :global .fileUploadField .imageView .imageHasOnlyId {
  flex: 0 1 30%;
  position: relative;
}

.File :global .removeFromPreview {
  position: absolute;
  right: 8px;
  padding: 2px 4px !important;
}

.File :global .fileUploadField .filesInput {
  flex: 1;
  display: flex;
  flex-direction: column;
}

:global .filesDropTarget {
  @apply border-gray-400 bg-gray-50 p-4 rounded;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-style: dashed;
}

:global .filesDropTarget.isOver {
  @apply border-gray-600;
  border: 2px solid;
}

:global .filesDropTarget p {
  text-align: center;
}

.File :global .upload-controls,
:global .upload-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.File :global .upload-controls .icons-file,
:global .upload-controls .icons-file {
  left: -28px;
  top: 0;
  position: absolute;
}

.File :global .upload-controls .text,
:global .upload-controls .text {
  padding: 0 5px;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.File :global .upload-controls .remove,
:global .upload-controls .remove {
  color: #999;
  line-height: 24px;
  font-size: 16px;
  padding: 0 5px;
}

.File :global .upload-controls .remove:hover,
:global .upload-controls .remove:hover {
  color: #666;
}

.File :global .upload-status,
:global .upload-status {
  position: relative;
  padding: 5px;
}

.File :global .upload-status .progress,
:global .upload-status .progress {
  margin: -5px;
}

.File :global .upload-progress,
:global .upload-progress {
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.File :global .upload-progress .bar,
:global .upload-progress .bar {
  background: #ddeefc none;
  width: 0%;
  height: 100%;
  color: white;
  float: left;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  transition: width 0.4s ease;
}

.File :global .upload-progress .bar-success,
:global .upload-progress .bar-success {
  background: #e8fcb9 none;
}

.File :global .upload-progress .bar-warning,
:global .upload-progress .bar-warning {
  background: #f89406 none;
}

.File :global .upload-progress .bar-danger,
:global .upload-progress .bar-danger {
  background: #ffbfc0 none;
}

.Html {
}

.Image {
}

.Number {
}

.Url {
}

.StateRow {
  margin-bottom: 10px;
}

.Selection input[type="text"],
.Selection input[type="text"]:focus {
  box-shadow: none;
  height: 18px;
  line-height: 18px;
}

.Selection :global .Select__control {
  min-height: 38px;
}

.Selection :global .Select__placeholder {
  font-size: 13px;
  color: rgb(164, 164, 164);
}

.Selection :global .Select__multi-value__label {
  padding-top: 1px;
  padding-bottom: 1px;
}

.Selection :global .Select__dropdown-indicator,
.Selection :global .Select__clear-indicator {
  padding: 4px;
}

.Selection :global .Select__value-container {
  padding: 0 calc(0.75rem - 2px);
}

.Selection :global .Select__input {
  margin: 0;
  padding: 0;
}

.Selection :global .Select__input input {
  margin: 0;
}

.Selection :global .Select__indicator-separator {
  margin: 4px 0;
}

.Relation {
  composes: Selection;
}

.Membership {
  composes: Selection;
}

.Tag {
  composes: Selection;
}
