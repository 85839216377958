.sp-replacer,
.sp-container {
  @apply border-box rounded bg-white;
}

.sp-replacer .sp-preview,
.sp-color,
.sp-hue,
.sp-initial,
.sp-val {
  @apply rounded !important;
  border-color: var(--border-box-border);
}

.sp-replacer:hover,
.sp-replacer.sp-active {
  @apply border-primary;
}

.sp-replacer.sp-disabled,
.sp-replacer.sp-disabled:hover {
  @apply border-neutral;
}

.sp-cancel {
  @apply border border-neutral rounded text-normal !important;
  font-size: 12px;
  padding: 4px 8px;
  background-color: var(--btn-light-background) !important;
  margin-right: 3px;
}

.sp-cancel:hover {
  @apply rounded text-normal  !important;
  text-decoration: none;
  filter: brightness(0.95);
}

.sp-container button.sp-choose {
  @apply bg-primary rounded mr-0 !important;
  background-image: none;
  color: var(--btn-primary-text);
  text-shadow: none;
  font-size: 12px;
  padding: 4px 8px;
  line-height: normal;
}

.sp-container button.sp-choose:hover {
  filter: brightness(0.9);
}

.sp-replacer.sp-disabled .sp-preview {
  opacity: 0.5;
}

.sp-container button:active {
  box-shadow: none;
}

.sp-input-container {
  margin-bottom: 10px;
}
