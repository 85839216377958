.table {
  /* This would be set by default if using plain bootstrap */
  th {
    text-align: left;
  }

  > :not(caption) {
    > .unreadMessage > * {
      background: var(--unread__content-background);
    }

    > .activeMessageRow > * {
      @apply bg-primary/10;
    }

    > .messageRow:hover > * {
      @apply bg-primary/5;
    }
  }
}
