.datepicker-controls .btn:hover {
  color: var(--userbar-link--hover-text);
  background: var(--userbar-link--hover-background);
}

.datepicker-cell:hover {
  color: var(--userbar-link--hover-text);
  background: var(--userbar-link--hover-background);
}

.datepicker-cell.selected {
  color: var(--userbar-link--active-text);
  background: var(--userbar-link--active-background);
}

.datepicker-cell.selected:hover {
  @apply underline;
  color: var(--userbar-link--active-text);
  background: var(--userbar-link--active-background);
}
