.DocumentActionButton img {
  width: 16px;
  height: 16px;
  vertical-align: -3px;
  margin-right: 2px;
}

:global(.btn-group) > .DocumentActionsSupText {
  font-size: 10px;
  display: block;
  height: 14px;
  padding: 0;
}
