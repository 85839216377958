.group-category-sorter .card-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem 1rem 1rem;
  margin-top: 10px;
}

.group-category-sorter .single-card {
  list-style: none;
  margin-bottom: 4px;
  padding-right: 10px;
  font-size: 22px;
  line-height: 44px;
  opacity: 1;
  background-color: #454545;
  color: white;
  cursor: move;
  margin: 5px 0;
  width: 500px;
  text-transform: uppercase;
}

.group-category-sorter .drag-card {
  opacity: 0;
}

.group-category-sorter .fa-padding {
  padding: 0px 10px 0px 10px;
}
