/* As used in Activitystreams, to be combined with .nav.nav-pills */

.filter-bar {
  @apply rounded border border-neutral py-2 px-3 gap-2;
}

.filter-bar .filter-bar__link {
  @apply py-1 px-3 text-sm text-normal leading-4 rounded-full bg-gray-100 flex gap-2;
}

.filter-bar .filter-bar__link:hover {
  @apply no-underline;
}

.filter-bar .filter-bar__link:hover .text {
  @apply underline;
}

.filter-bar .filter-bar__link.active {
  @apply bg-primary text-white;
}

.filter-bar .filter-bar__link.active:hover {
  @apply text-white;
}
