.activity-file > .file-infos {
  transform: translateX(0);
}

.activity-file.show-lock > .file-infos {
  transform: translateX(-100%);
}

.activity-file > .file-lock {
  transform: translateX(100%);
}

.activity-file.show-lock > .file-lock {
  transform: translateX(0);
}

.file-container.hide > .activity-file:nth-of-type(1n + 3) {
  display: none;
}

/* file type icons*/
.file-type {
  color: #999;
}

.file-type-pdf {
  color: #c97056;
}

/* office-text file extensions */
.file-type-odt,
.file-type-docx,
.file-type-docm,
.file-type-dotm,
.file-type-dotx,
.file-type-pages,
.file-type-doc {
  color: #8db4da;
}

/* office-spreadsheet file extensions */
.file-type-xls,
.file-type-xlsx,
.file-type-xlsm,
.file-type-xlam,
.file-type-xlsb,
.file-type-xltm,
.file-type-xltx,
.file-type-csv,
.file-type-numbers,
.file-type-ods {
  color: #aec566;
}

/* office-presentation file extensions */
.file-type-potm,
.file-type-potx,
.file-type-ppam,
.file-type-ppsm,
.file-type-ppsx,
.file-type-pptm,
.file-type-pptx,
.file-type-ppt,
.file-type-pps,
.file-type-odp,
.file-type-key {
  color: #d18d5c;
}

/* images file extensions */
.file-type-png,
.file-type-jpg,
.file-type-bmp,
.file-type-gif,
.file-type-jpeg {
  color: #8ab472;
}

/* audio file extensions */
.file-type-aif,
.file-type-cda,
.file-type-mid,
.file-type-midi,
.file-type-map3,
.file-type-mpa,
.file-type-ogg,
.file-type-wav,
.file-type-wma,
.file-type-aac,
.file-type-flax,
.file-type-alac,
.file-type-wpl {
  color: #b18eb8;
}

/* video file extensions */
.file-type-webm,
.file-type-mkv,
.file-type-flv,
.file-type-vob,
.file-type-ogv,
.file-type-drc,
.file-type-gifv,
.file-type-avi,
.file-type-mov,
.file-type-mts,
.file-type-wmv,
.file-type-yuv,
.file-type-amv,
.file-type-mp4,
.file-type-m4p,
.file-type-m4v,
.file-type-mpg,
.file-type-mpeg,
.file-type-m2v,
.file-type-svi,
.file-type-3gp,
.file-type-flv,
.file-type-f4v,
.file-type-f4p,
.file-type-f4a,
.file-type-f4b,
.file-type-3g2,
.file-type-mk4v {
  color: #a495bf;
}

/* compressed file extensions */
.file-type-7z,
.file-type-arj,
.file-type-deb,
.file-type-rpm,
.file-type-rar,
.file-type-z,
.file-type-gz,
.file-type-pkg,
.file-type-zip {
  color: #e3bb5e;
}

/* disc and media file extensions */
.file-type-bin,
.file-type-dmg,
.file-type-iso,
.file-type-exe,
.file-type-app,
.file-type-vcd {
  color: #b1b9bf;
}
