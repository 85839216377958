.backgrid-pagination ul {
  @apply flex gap-2 justify-center mb-4;
}

.backgrid-filter {
  @apply flex;
}

.backgrid-filter a.clear {
  @apply !hidden;
}

.grid table.tixxt-table tbody td {
  padding: 0;
}
