@tailwind base;

@layer base {
  /*
    Added for /administration/statistics, to make date inputs the same height
    across all browsers.
  */
  input[type="date"] {
    height: 38px;
  }
}
