.question-content-container {
  @apply flex border rounded p-2 items-center;
  border-color: var(--question-border);
  background: var(--question-background);
}

.question-content-container .prose {
  color: var(--question-text);
}

.question-mark {
  @apply text-5xl flex-none w-16 h-16 pr-2 flex items-center justify-center self-start;
  color: var(--question--question-mark);
}

.answer-container {
  @apply p-3 rounded-lg rounded-tl-none;
  background: var(--question-answer-background);
}

.answer-container .prose {
  color: var(--question-answer-text, var(--question-text));
}
