.mfp-image-holder button.mfp-close {
  color: white;
  top: 35px;
  right: 0px;
  text-shadow:
    1px 1px 3px black,
    -1px -1px 3px black;
}

.mfp-title a,
.mfp-title a:hover {
  color: white;
}

img.mfp-img {
  @apply rounded;
  margin: 40px 0 40px;
  padding: 0;
}
