.navigation-manager .content-item {
  border-radius: 3px;
  padding: 0;
  margin: 10px 10px 10px 0;
  height: 45px;
}

.navigation-manager .selected-option,
.navigation-manager .selected > .rst__rowContents {
  background: #f2f2f2;
}

.navigation-manager .content-item > .rst__rowContents {
  width: 240px;
  border: none;
  box-shadow: none;
  padding-left: 0.5rem;
}

.navigation-manager .content-item > .rst__rowContents .rst__rowLabel {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
}

.navigation-manager .checkbox-container {
  text-align: left;
  margin-bottom: 10px;
}

.navigation-manager .checkbox {
  margin: 10px 10px 10px 0;
}

.navigation-manager .rst__moveHandle {
  border: 0;
  box-shadow: none;
  @apply bg-gray-300 w-8;
}

.navigation-manager .rst__lineHalfHorizontalRight::before,
.navigation-manager .rst__lineFullVertical::after,
.navigation-manager .rst__lineHalfVerticalTop::after,
.navigation-manager .rst__lineHalfVerticalBottom::after,
.navigation-manager .rst__lineChildren::after {
  background: #e1e1e1;
}

.navigation-manager .rst__collapseButton,
.navigation-manager .rst__expandButton {
  box-shadow: none;
  border: 1px solid #e1e1e1;
}

.navigation-manager .rst__collapseButton:focus,
.navigation-manager .rst__expandButton:focus {
  box-shadow: none;
}

.navigation-manager .input-text {
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  min-height: 26px;
  box-sizing: border-box;
  padding-left: 9px;
}

.navigation-manager {
  display: flex;
  justify-content: space-between;
}

.navigation-manager .navigation-column {
  height: 100%;
  flex: 1;
  align-self: stretch;
  border-right: 1px solid #e1e1e1;
  margin-right: 50px;
  margin-bottom: 30px;
}

.navigation-manager .input-column {
  width: 350px;
}

.navigation-manager .navigation-manager-form {
  position: sticky;
  top: 20px;
}

.navigation-manager .navigation-manager-form form {
  text-align: left;
}

.navigation-manager .message {
  margin-left: 20px;
}

.navigation-manager .add-navigation-child-button {
  margin-right: 5px;
}

.navigation-manager .add-new-item-button {
  margin-left: 22px;
  margin-top: 20px;
}

.navigation-manager .navigation-column .add-new-item-button i {
  margin-right: 5px;
  font-size: 12px;
}

.navigation-manager .empty-field {
  border: 1px solid rgb(250, 117, 117) !important;
}

.navigation-manager .not-empty-field {
  border: 1px solid #e1e1e1;
}

.entry-point {
  color: #006dce;
}

.hint {
  margin: 20px 20px -10px 20px;
}
