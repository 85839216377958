@layer components {
  .alert-info {
    --bs-alert-bg: var(--alert-info-background);
    --bs-alert-color: var(--alert-info-text);
    --bs-alert-border-color: var(--alert-info-border);
  }

  .alert-warning {
    --bs-alert-bg: var(--alert-warning-background);
    --bs-alert-color: var(--alert-warning-text);
    --bs-alert-border-color: var(--alert-warning-border);
  }

  .alert-danger,
  .alert-error {
    --bs-alert-bg: var(--alert-danger-background);
    --bs-alert-color: var(--alert-danger-text);
    --bs-alert-border-color: var(--alert-danger-border);
  }

  .alert-success {
    --bs-alert-bg: var(--alert-success-background);
    --bs-alert-color: var(--alert-success-text);
    --bs-alert-border-color: var(--alert-success-border);
  }
}
