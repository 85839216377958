/* START Sticky styles */
:global .activity-view.closed .main,
:global .activity-view.closed .sticky-footer,
:global .activity-view.closed .close-sticky {
  display: none;
}

:global .activity-view.closed .sticky-header,
:global .activity-view.closed .open-sticky {
  display: initial;
}

:global .activity-view .open-sticky,
:global .activity-view .sticky-header {
  display: none;
}

:global .activity-view .sticky-footer .confirmation {
  display: block;
}

:global .activity-view.confirmed-sticky .sticky-footer .confirmation {
  display: initial;
}

:global .activity-view.confirmed-sticky .sticky-footer .confirm {
  display: none;
}

/* END Sticky styles */

:global .activity-view {
  transition: background-color 3s;
}

:global .activity-view.unread {
  background-color: var(--unread__content-background);
}

.activityAnswerCount {
  padding: 5px;
}

.calendarName {
  float: right;
  padding-left: 3px;
}

.activityImage {
  margin-right: 5px;
}

.imageThumbs {
  margin-top: 10px;
}
