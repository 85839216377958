.fileinput-button {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.fileinput-button input {
  position: absolute;
  top: 0;
  right: -50px;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  transform: translate(-250px, 0) scale(4);
  font-size: 23px;
  direction: ltr;
  cursor: pointer;
}

.activity-composer.closed .attachments-region,
.activity-composer.closed .actions,
.activity-composer.closed .targeting-region {
  display: none;
}

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  @apply prose;
}
