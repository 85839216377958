.checkboxfield__label {
  display: inline-block;
}

body.mobile .label {
  display: block;
}
body.mobile .additionalText {
  display: block;
  float: initial;
}
